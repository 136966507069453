<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />
      <div class="filter-bar boxarea">
        <SelectLayer />
        <!-- <button>CS 관리현황 보기</button> -->
      </div>
      <section class="review-management">
        <div class="boxarea">
          <h2>{{ $t("negative_management") }}</h2>
          <p v-if="currentLang == 'ko'">
            {{ term == "" ? "현재까지" : "지난" + term + "개월동안" }} 받은 리뷰
            {{ comma(chartTotalCount) }}개 중, 별점 3점 이하를 받은 리뷰가
            {{ underThree }}개 입니다.
            <br />
            {{ term == "" ? "현재까지" : "지난" + term + "개월동안" }} 감성 점수
            Negative를 받은 리뷰가 <span>{{ sentiment_2 }}</span
            >개 입니다.
          </p>
          <p v-else>
            {{ comma(underThree) }} of the reviews
            {{ term == "" ? "until now" : "in the last " + term + " months" }}
            rated 3 stars or less.
            <br />
            {{ comma(sentiment_2) }} of the reviews
            {{ term == "" ? "until now" : "in the last " + term + " months" }}
            rated negative sentiment score.
          </p>
          <div class="chartBox">
            <VueApexCharts
              :class="{ fullSize: index == 1 }"
              ref="chart"
              type="line"
              height="500"
              :width="chartWidth"
              :options="chartOptions"
              :series="series"
            ></VueApexCharts>
          </div>
        </div>
        <div class="boxarea-table">
          <table class="infinite-list-wrapper" v-infinite-scroll="load">
            <tr>
              <td class="title">
                <span>{{ $t("dissatisfied_reviews_comment") }}</span>
              </td>
              <td>Date</td>
              <td>Rating</td>
              <td>
                <router-link to @click.native="handleSort()">
                  {{ $t("response_requirment_rate") }}
                  <span v-if="sorting == 1">▼</span>
                  <span v-else>▲</span>
                </router-link>
              </td>
              <!-- <td>CS 할당</td> -->
            </tr>
            <tr v-for="(item, i) in reviewList" :key="i">
              <td>
                {{ item.review_source }} {{ item.reviewer_name }}
                <p>{{ item.review }}</p>
              </td>
              <td>{{ moment(item.review_date).format("YYYY.MM.DD") }}</td>
              <td>{{ item.rating }}</td>
              <td>
                <Small-Donut :dataA="[item.NPS_Score]" />
              </td>
              <!-- <td>
                <input
                  type="checkbox"
                  :checked="checkItems.indexOf(item.id) >= 0 ? true : false"
                  @change="handleChecked($event, item.id)"
                />
              </td>-->
            </tr>
          </table>

          <p v-show="loading" class="loading">Loading...</p>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import SmallDonut from "@/components/charts/SmallDonut";
import VueApexCharts from "vue-apexcharts";
// import LineColumn2 from "@/components/charts/LineColumn2";
import TopBar from "@/components/dashboard/TopBar";
import Navbar from "@/components/Navbar";
import SelectLayer from "@/components/dashboard/SelectLayer";
import {
  fetchNegativeReviewList,
  // fetchReviewStatus,
  fetchReviewRating,
  fetchSentimentPred,
  fetchNegativeRating,
} from "@/api/review";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import moment from "moment";
export default {
  mixins: [format],
  components: { VueApexCharts, SmallDonut, TopBar, Navbar, SelectLayer },
  computed: {
    ...mapState(["term", "asin", "currentLang"]),
  },
  data() {
    return {
      moment: moment,
      index: "1",
      series: [],
      dataC: [80],
      reviewList: [],
      currentPage: 1,
      totalPages: 0,
      loading: false,
      checkItems: [],
      review_X: [],
      reviewStatus: [],
      sorting: "",
      totalReviewCount: 0,
      ratingTotalCount: 0,
      chartTotalCount: 0,
      maxYCount: 0,
      sentiment_2: 0,
      underThree: 0,
      ratings: [],
      type: "",
      labels: [],
      reviewsPerMonth: [],
      oneReviews: [],
      twoReviews: [],
      threeReviews: [],
      negReviewCount: 0,
      chartWidth: this.width,

      chartOptions: {
        colors: ["#E0EBFE", "#57E69C", "#6B93CC", "#D365A8"],
        chart: {
          height: 350,
          type: "line",
          background: "#fafafa",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "40%",
          },
        },
        fill: {
          colors: ["#E0EBFE"],
        },
        stroke: {
          curve: "smooth",
          width: [0, 2, 2, 2], // 그래프 두께 series배열 순서
          colors: ["#D365A8", "#57E69C", "#6B93CC"],
        },
        dataLabels: {
          enabled: false, // 바 그래프에 값 표시
          enabledOnSeries: [1, 2, 3],
        },
        legend: {
          position: "bottom",
          markers: {
            fillColors: ["#E0EBFE", "#57E69C", "#6B93CC", "#D365A8"],
          },
        },
        xaxis: {
          type: "category",
          categories: [],
        },
        yaxis: [
          {
            show: true,
            min: 0,
            max: function () {
              this.maxYCount < 5 ? 5 : this.maxYCount;
            },
          },
        ],
      },
    };
  },
  watch: {
    asin: function () {
      this.mergeData();
    },
    term: function () {
      this.mergeData();
      if (this.term == "") {
        this.index = 1;
      } else {
        this.index = 2;
      }
    },
  },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 7);
  },
  mounted() {
    this.mergeData();
    this.getNegativeReviewList();
  },
  methods: {
    handleSort() {
      if (this.sorting == "") {
        this.sorting = 1;
      } else if (this.sorting == -1) {
        this.sorting = 1;
      } else if (this.sorting == 1) {
        this.sorting = -1;
      }
      this.reviewList = [];
      this.getNegativeReviewList();
    },
    handleChecked(e, idx) {
      if (e.target.checked) {
        this.checkItems = [...this.checkItems, idx];
      } else {
        this.checkItems = this.checkItems.filter((id) => idx !== id);
      }
    },
    load() {
      if (this.currentPage > this.totalPages) {
        return;
      }
      this.currentPage++;
      this.getNegativeReviewList();
    },
    /**
     * +-----------------------------+
     * | 불만족 리뷰 / 낮은 별점 리뷰 데이터 |
     * +-----------------------------+
     */
    getNegativeReviewList() {
      this.loading = true;
      let params = {
        page: this.currentPage,
        type: this.type,
        sorting: this.sorting,
      };
      fetchNegativeReviewList(params).then((res) => {
        if (res.data.status == 200) {
          if (this.reviewList.length > 0) {
            this.reviewList = [...this.reviewList, ...res.data.data];
          } else {
            this.reviewList = res.data.data;
          }
          this.currentPage = res.data.currentPage;
          this.totalPages = res.data.totalPages;
        } else {
          return alert(res.data.message);
        }
        setTimeout(() => {
          this.loading = false;
        }, 6000);
      });
    },
    sumArray(array) {
      let result = 0.0;

      for (let i = 0; i < array.length; i++) result += array[i];

      return result;
    },

    // getReviewStatus() {
    //   fetchReviewStatus(this.asin).then(res => {

    //     this.labels = [];
    //     this.reviewsPerMonth = [];
    //     this.posReviews = [];
    //     this.neuReviews = [];
    //     this.negReviews = [];

    //     for (let i = 12 - this.term; i < 12; i++) {
    //       this.labels.push(res.data.data.labels[i]);
    //       this.reviewsPerMonth.push(res.data.data.reviewsPerMonth[i]);
    //       this.posReviews.push(res.data.data.posReviews[i]);
    //       this.neuReviews.push(res.data.data.neuReviews[i]);
    //       this.negReviews.push(res.data.data.negReviews[i]);
    //     }
    //     this.totalReviewCount = this.sumArray(this.reviewsPerMonth);
    //     this.negReviews.forEach(item => {
    //       this.negReviewCount += item;
    //     });
    //     if (res.status == 200) {
    //       this.series = [
    //         {
    //           name: "부정적리뷰",
    //           type: "column",
    //           data: this.reviewsPerMonth
    //         },
    //         {
    //           name: "3점",
    //           type: "line",
    //           data: this.posReviews
    //         },
    //         {
    //           name: "2점",
    //           type: "line",
    //           data: this.neuReviews
    //         },
    //         {
    //           name: "1점",
    //           type: "line",
    //           data: this.negReviews
    //         }
    //       ];

    //       this.$refs.chart.updateOptions(
    //         {
    //           xaxis: {
    //             categories: this.labels
    //           }
    //         },
    //         true
    //       );
    //       // this.review_X = res.data.data.labels;
    //       // this.chartOptions.labels = res.data.data.labels;
    //     }
    //   });
    // },
    async getNegativeRatings() {
      this.reviewsPerMonth = [];
      this.threeReviews = [];
      this.twoReviews = [];
      this.oneReviews = [];
      this.labels = [];

      let params = {
        asin: this.asin,
        term: this.term,
      };

      fetchSentimentPred(params).then((res) => {
        if (res.status == 200) {
          this.sentiment_2 = res.data.data.sentiment[2];
        } else {
          return alert(res.data.message);
        }
      });
      fetchReviewRating(params).then((res) => {
        if (res.status == 200) {
          this.ratings = res.data.data.rating;
          let data = [];
          for (let i = 5; i > 0; i--) {
            let input = 0;
            for (let j = 0; j < this.ratings.length; j++) {
              if (this.ratings[j]._id == i) {
                input = this.ratings[j].count;
              }
            }
            data.push(input);
          }
          this.underThree = 0;
          data.forEach((item, i) => {
            if (i > 1) {
              this.underThree += item;
            }
          });
        }
      });

      await fetchNegativeRating(params).then((res) => {
        this.labels = res.data.data.labels;
        this.reviewsPerMonth = res.data.data.reviewsPerMonth;
        this.oneReviews = res.data.data.onePoint;
        this.twoReviews = res.data.data.twoPoint;
        this.threeReviews = res.data.data.threePoint;
        // for (let i = 12 - this.chartTerm; i < 12; i++) {
        //   this.labels.push(res.data.data.labels[i]);
        //   this.reviewsPerMonth.push(res.data.data.reviewsPerMonth[i]);
        //   this.oneReviews.push(res.data.data.onePoint[i]);
        //   this.twoReviews.push(res.data.data.twoPoint[i]);
        //   this.threeReviews.push(res.data.data.threePoint[i]);
        // }
      });
    },

    /**
     * +-----------------------------+
     * | 차트 별점 3점 이하 받은 리뷰 카운팅 |
     * +-----------------------------+
     */
    // getReviewRating() {
    //   let params = {
    //     asin: this.asin,
    //     term: this.term
    //   };
    //   fetchReviewRating(params).then(res => {

    //     if (res.status == 200) {
    //       this.ratingTotalCount = res.data.data.totalCount;
    //       this.ratings = res.data.data.rating;
    //       let data = [];
    //       for (let i = 5; i > 0; i--) {
    //         let input = 0;
    //         for (let j = 0; j < this.ratings.length; j++) {
    //           if (this.ratings[j]._id == i) {
    //             input = this.ratings[j].count;
    //           }
    //         }
    //         data.push(input);
    //       }

    //       data.forEach((item, i) => {
    //         if (i > 1) {
    //           this.underThree += item;
    //         }
    //       });
    //     }
    //   });
    // },
    async mergeData() {
      await this.getNegativeRatings();
      this.chartTotalCount = 0;

      this.maxYCount = Math.max(...this.reviewsPerMonth);
      this.reviewsPerMonth.forEach((item) => {
        this.chartTotalCount += item;
      });
      this.series = [
        {
          name: "negative reviews",
          type: "column",
          data: this.reviewsPerMonth,
        },
        {
          name: "3P",
          type: "line",
          data: this.threeReviews,
        },
        {
          name: "2P",
          type: "line",
          data: this.twoReviews,
        },
        {
          name: "1P",
          type: "line",
          data: this.oneReviews,
        },
      ];

      this.$refs.chart.updateOptions(
        {
          xaxis: {
            categories: this.labels,
          },
        },
        true
      );
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
