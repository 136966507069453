<template>
  <div>
    <div class="filter-box">
      <!-- <h2>제품 선택</h2>
      <select v-model="selected" @change="handleProduct">
        <option value selected>전체 선택</option>
        <option v-for="(item, i) in productList" :key="i" :value="item.asin">
          {{ item.productName }}
        </option>
      </select> -->
      <h2>{{ $t("period") }}</h2>
      <select v-model="term" @change="changeTerm(term)">
        <option value="">{{ $t("whole_period") }}</option>
        <option value="12">{{ $t("last_12month") }}</option>
        <option value="9">{{ $t("last_9month") }}</option>
        <option value="6">{{ $t("last_6month") }}</option>
        <option value="3">{{ $t("last_3month") }}</option>
        <!-- <option value="12">지난 12개월</option> -->
      </select>
    </div>
  </div>
</template>
<script>
import { fetchProductList } from "@/api/product";
import { mapState } from "vuex";
import lang from "@/mixins/lang";
export default {
  mixins: [lang],
  data() {
    return {
      dataC: [80],
      productList: "",
      term: "", // default 기간 설정
      selected: "all",
    };
  },
  computed: {
    ...mapState(["asin"]),
  },
  mounted() {
    this.getProductInfo();
  },
  methods: {
    getProductInfo() {
      fetchProductList().then((res) => {
        if (res.status == 200) {
          this.productList = res.data.data;
        }
      });
    },
    handleProduct() {
      if (this.selected == "all") {
        this.$store.dispatch("SET_ASIN", "");
      } else if (this.selected) {
        this.$store.dispatch("SET_ASIN", this.selected);
      }
    },
    changeTerm(term) {
      window.console.log(term);
      this.$store.dispatch("VIEW_TERM", term);
    },
  },
};
</script>
