<template>
  <div>
    <h2>{{ title }}</h2>
    <VueApexCharts
      ref="simpleDonut"
      type="radialBar"
      height="100"
      :options="chartOptions"
      :series="series"
    ></VueApexCharts>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    dataA: {
      type: Array,
    },
  },
  data() {
    return {
      series: this.dataA,
      chartOptions: {
        chart: {
          height: 280,
          type: "radialBar",
        },
        fill: {
          colors: "#d03478",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "35%",
            },
            track: {
              background: "#ccc",
            },

            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                color: "#111",
                fontSize: "12px",
                show: true,
                formatter: function (val) {
                  let result;
                  if (val >= 90) {
                    result = "A+";
                  } else if (val < 90 && val >= 80) {
                    result = "A";
                  } else if (val < 80 && val >= 70) {
                    result = "B+";
                  } else if (val < 70 && val >= 60) {
                    result = "B";
                  } else if (val < 60) {
                    result = "D";
                  }
                  return result;
                },
              },
            },
          },
        },

        stroke: {
          lineCap: "round",
        },
        labels: ["Progress"],
      },
    };
  },
};
</script>
